import React from 'react'
import { Col, Row, Image, Dropdown, DropdownButton, InputGroup, Form, Button } from 'react-bootstrap'

const MomentTop = () => {
    return (
        <div className='moment-section'>
            <Row>
                <Col md={11} className="mx-auto overflow-hidden">
                    <Row>
                        <Col md={12} lg={7} data-aos="slide-up" data-aos-duration="1000">
                            <Image src={require('./../../assets/images/moment.png')} className='img-fluid' />
                        </Col>
                        <Col md={12} lg={5} data-aos="slide-down" data-aos-duration="1000">

                            <div className="form-section">
                                <Form.Group className="mb-4" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control type="text" placeholder="Your name" />
                                </Form.Group>


                                <Form.Group className="mb-4" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control type="email" placeholder="you@company.com" />
                                </Form.Group>


                                <Form.Group className="mb-4" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Phone number</Form.Label>
                                    <InputGroup className="mb-4">
                                        <DropdownButton
                                            variant="outline-secondary"
                                            title="US"
                                            id="input-group-dropdown-1"
                                        >
                                            <Dropdown.Item href="#">PK</Dropdown.Item>
                                            <Dropdown.Item href="#">US</Dropdown.Item>
                                            <Dropdown.Item href="#">UK</Dropdown.Item>
                                        </DropdownButton>
                                        <Form.Control aria-label="Text input with dropdown button" placeholder='+1 (555) 000-0000' />
                                    </InputGroup>

                                </Form.Group>

                                <Button variant='danger' className="w-100">Get Started</Button>

                            </div>


                            <div className="form-section">
                                <Form.Group className="mb-4" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control type="text" placeholder="Your name" />
                                </Form.Group>


                                <Form.Group className="mb-4" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control type="email" placeholder="you@company.com" />
                                </Form.Group>


                                <Form.Group className="mb-4" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Phone number</Form.Label>
                                    <InputGroup className="mb-4">
                                        <DropdownButton
                                            variant="outline-secondary"
                                            title="US"
                                            id="input-group-dropdown-1"
                                        >
                                            <Dropdown.Item href="#">PK</Dropdown.Item>
                                            <Dropdown.Item href="#">US</Dropdown.Item>
                                            <Dropdown.Item href="#">UK</Dropdown.Item>
                                        </DropdownButton>
                                        <Form.Control aria-label="Text input with dropdown button" placeholder='+1 (555) 000-0000' />
                                    </InputGroup>
                                </Form.Group>

                                <Button variant='danger' className="w-100">Get Started</Button>
                                <Form.Text id="passwordHelpBlock" muted>
                                    SMS marketing powered by <a href=''>SimpleTexting</a>
                                </Form.Text>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>

        </div>
    )
}

export default MomentTop
import React from 'react'

const TextSection = () => {
    return (
        <div className='text-section overflow-hidden'>
            <p data-aos="slide-down" data-aos-duration="1000">There are 1.2 Million Muslims in NYC. Out of $101 billion of New York City budget we contribute $17 billion towards it's revenue but how much of our taxes and revenue is coming back to our community is negligible. You can change it by actively participating in the political system and electing our own Muslim candidates into these offices.</p>
        </div>
    )
}

export default TextSection
import React from 'react'
import { Col, Row, Image, Dropdown, DropdownButton, InputGroup, Form, Button } from 'react-bootstrap'

const PartnerForm = () => {
    return (
        <div className='partner-form-section'>

            <Row>
                <Col>
                    <div className="form-section">
                        <h1>Join Our Civic Table</h1>
                        <Form.Group className="mb-4" controlId="exampleForm.ControlInput1" data-aos="zoom-in" data-aos-duration="1000">
                            <Form.Label>Organization Name</Form.Label>
                            <Form.Control type="text" placeholder="Your organization name..." />
                        </Form.Group>


                        <Form.Group className="mb-4" controlId="exampleForm.ControlInput1" data-aos="zoom-in" data-aos-duration="1000" data-aos-delay="500">
                            <Form.Label>Address</Form.Label>
                            <Form.Control type="text" placeholder="Your address here..." />
                        </Form.Group>


                        <Form.Group className="mb-4" controlId="exampleForm.ControlInput1" data-aos="zoom-in" data-aos-duration="1000" data-aos-delay="1000">
                            <Form.Label>Phone number</Form.Label>
                            <InputGroup className="mb-4">
                                <DropdownButton
                                    variant="outline-secondary"
                                    title="US"
                                    id="input-group-dropdown-1"
                                >
                                    <Dropdown.Item href="#">PK</Dropdown.Item>
                                    <Dropdown.Item href="#">US</Dropdown.Item>
                                    <Dropdown.Item href="#">UK</Dropdown.Item>
                                </DropdownButton>
                                <Form.Control aria-label="Text input with dropdown button" placeholder='+1 (555) 000-0000' />
                            </InputGroup>
                        </Form.Group>

                        <Button variant='danger' className="w-100" data-aos="zoom-in" data-aos-duration="1000" data-aos-delay="1500">SUBMIT</Button>

                    </div>

                </Col>
            </Row>

            <Row className='p-2 mb-4'>
                <Col>
                    <h1>What we expect from our partners?</h1>
                    <ul>
                        <li>Believe democracy should be accessible to all and are ready to engage their staff, clients, members, and community in the democratic process</li>
                        <li>Would benefit from direct assistance in planning and implementing voter engagement activities</li>
                    </ul>
                </Col>
            </Row>


            <Row className='p-2'>
                <Col md={11} className="mx-auto third-sectin">
                    <Row>
                        <Col md={12} lg={4}>
                            <Image src={require('./../../assets/images/partner.png')} className='img-fluid' alt="partern" data-aos="slide-right" data-aos-duration="1000" />
                        </Col>
                        <Col md={12} lg={7} className="p-4" data-aos="slide-left" data-aos-duration="1000">
                            <h1>What we expect from our partners?</h1>
                            <p><strong>Direct Strategic Support</strong> -– Gain access to expert knowledge on nonpartisanship, voter engagement, engaging with candidates, and issue advocacy to build a strategic plan</p>
                            <p><strong>Personalized Webinars</strong> - – Receive webinars tailored specifically to your needs
                                In-Person Training – Our expert staff can provide interactive, personalized training sessions to your organization and affiliates</p>
                            <p><strong>Customized Toolkits and Resources</strong>  – We can develop voter engagement toolkits or personalized resources that fit the needs of your organization. Toolkits and resources can cover any topics related to voter and candidate engagement or staying nonpartisan.</p>
                            <p><strong>Publicity for Your Organization</strong>  – Your logo will be featured prominently on our website and you will be given the opportunity to be highlighted across our communications platforms</p>
                            <p><strong>Connect with Civic Nonprofit Leaders</strong>  – Joining our network affords you the opportunity to connect with other voter engagement leaders committed to improving democracy.</p>
                            <p>We integrate voter engagement into their ongoing activities and services. In doing so, we work to promote higher voter and civic participation, especially among populations new to the process or with a recent history of lower voter turnout.</p>
                            <p>We know that you are unique and ideally positioned to effectively engage yourself and the communities you serve in democracy. We want to help you get there.</p>
                            <p>We want to make sure that you have the information you need, your staff needs, and your community needs to play a key role in promoting equity in democracy for all.</p>
                            <p>We are always seeking national, state, or regional service-based nonprofits to join us as partners in voter engagement. Now is the perfect time to join us in making sure democracy is accessible to everyone.</p>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    )
}

export default PartnerForm
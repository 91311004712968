import React from 'react'
import Getinvoled from '../section/getinvole'
import TopHeader from '../components/topheader'
import AboutTop from '../section/abouttop'

const About = () => {
    document.title = "MUSLIM VOTE | ABOUT US";
    const topText = {
        title: "ABOUT US",
    }
    return (
        <div>
            <TopHeader text={topText} />
            <AboutTop />
            <Getinvoled />
        </div>
    )
}

export default About
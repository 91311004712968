import React from 'react'
import TopHeader from '../components/topheader'

import Getinvoled from '../section/getinvole'
import PartnerForm from '../section/partnerform'
import OurPartner from '../section/ourpertner'

const Partner = () => {
    document.title = "MUSLIM VOTE | PARTNER WITH US";
    const topText = {
        title: "PARTNER WITH US",
    }
    return (
        <div>
            <TopHeader text={topText} />
            <PartnerForm />

            <OurPartner />
            <Getinvoled />
        </div>
    )
}

export default Partner
import React from 'react'

const Impact = () => {
    return (
        <div className='impact' data-aos="zoom-in" data-aos-duration="1000">
            <h1>Impact</h1>
            <p>Through amplifying Muslim voices and sharing stories that show the impact of crucial voting legislation, we spread awareness on how policies and legislation on voting affects the everyday lives of Muslims.</p>
        </div>
    )
}

export default Impact
import React from 'react'
import TopHeader from '../components/topheader'
import Mission from '../section/mission'
import Getinvoled from '../section/getinvole'

const Manage = () => {
    document.title = "MUSLIM VOTE | VOTER ENGAGEMENT";
    const topText = {
        title: "VOTER ENGAGEMENT",
    }
    const section = {
        title: "Vote for your future",
        des: "",
        text: 'Vote for leaders that are committed to solving these   problems for you and for future generations.',
        side: "left",
        img: "manag1.png",
        ul: ["Worried about climate change? ", "Thinking about how you’ll pay off your student  loans?", "Hoping you’ll have health insurance when you need it?"]
    }
    const section2 = {
        title: "Serving Our Community",
        text: "Serving our community’s needs – like funding for better schools for our children.  Gives us the opportunity to come together and advocate for the future of our community and our children.",
        side: "right",
        img: "manag2.png",
        bg: "red"
    }
    const section3 = {
        title: "Join Together",
        text: `Speak out for your community <br /> 
        By joining together we can ensure we have the opportunity to elect leaders who will truly represent us. Speak out for your community, your family, and your future today!
        `,
        side: "reverse",
        img: "manag3.png",

    }
    return (
        <>
            <TopHeader text={topText} />
            <Mission data={section} />
            <Mission data={section2} />
            <Mission data={section3} />

            <Getinvoled />
        </>
    )
}

export default Manage
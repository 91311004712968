import React from 'react'
import { Image } from 'react-bootstrap'
import Involed from './../../assets/images/involed.png'
import { FaLongArrowAltRight } from "react-icons/fa";
import { Link } from 'react-router-dom';
const Getinvoled = () => {
    return (
        <div className='get-involed-section'>
            <Image src={Involed} alt="involed" />
            <div className='overlay' data-aos="zoom-in" data-aos-duration="1000" >
                <h1>Get Involved</h1>
                <p>By mobilizing the power of volunteers we can create an impact. Volunteers are essential to fulfilling our purpose to strengthen communities and able to positively impact lives in significant and lasting ways.</p>
                <Link to="/volunteer" className="glassy-btn">Become a volunteer  &nbsp; &nbsp; <FaLongArrowAltRight /></Link>
            </div>
        </div>
    )
}

export default Getinvoled
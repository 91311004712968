import React from 'react'
import { Col, Row, Image, Form, Button } from 'react-bootstrap'
import logo from './../../assets/images/logo.svg'
import footerimg from './../../assets/images/footer_img.png'
import { FaFacebookF, FaInstagram, FaTwitter, FaYoutube } from "react-icons/fa";
const Footer = () => {
    return (
        <div className='footer'>
            <div className='footer-overlay'>
                <Row>
                    <Col md={11} className="mx-auto">
                        <Row>
                            <Col xs={12} sm={6} lg={5} className="footer-about">
                                <Image src={logo} alt="logo" />
                                <p>Muslim Vote Project (MVP) is a nonprofit 501(c)4 dedicated towards engaging, educating, and empowering Muslim communities to strengthen their voices and create impact in every election. </p>
                                <Image src={footerimg} alt="logo" className="img-fluid" />
                            </Col>
                            <Col xs={12} sm={6} lg={2} className="link">
                                <ul>
                                    <li><a href="">Voter Engagement</a></li>
                                    <li><a href="">Voter Education</a></li>
                                    <li><a href="">Young Muslims <br /> Democratic Club</a></li>
                                    <li><a href="">Join The Movement</a></li>
                                    <li><a href="">Terms & Conditions</a></li>
                                    <li><a href="">Privacy Policy</a></li>
                                </ul>
                            </Col>
                            <Col xs={12} sm={6} lg={2} className="contact">
                                <ul>
                                    <li><a href="">Contact Us : <br /> +1 (929) 308-9191</a></li>
                                    <li><a href="">Email : <br /> Info@muslimvote.org</a></li>
                                    <li><a href="">Location:  <br />
                                        984 Coney Island Brooklyn, New York, 11230.</a></li>
                                </ul>
                            </Col>

                            <Col xs={12} sm={6} lg={3} className="newslatter">
                                <a href="">Newsletter</a>
                                <Form.Group className="my-3" controlId="exampleForm.ControlInput1">
                                    <Form.Control type="email" className="p-3" placeholder="Enter your email address" />
                                </Form.Group>
                                <Button variant='danger' className="w-100 p-3 h-auto">Subscribe Now</Button>
                                <ul>
                                    <li><a href=""><FaFacebookF /></a></li>
                                    <li><a href=""><FaInstagram /></a></li>
                                    <li><a href=""><FaTwitter /></a></li>
                                    <li><a href=""><FaYoutube /></a></li>
                                </ul>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default Footer
import React from 'react'
import ServiceItem from '../../components/serviceitem'
import { Col, Row } from 'react-bootstrap'
const Service = () => {

    const data = [
        {
            title: "Manage",
            des: "We manage the 1-888-MVP-VOTE election protection and information hotline to answer state-specific questions or concerns about voting for callers in the language they are most comfortable in.",
            img: "s1.png"
        },
        {
            title: "Partners",
            des: "Along with local and national partners, we provide assistance in making sure appropriate in-language materials are made available to local election officials as they expand language accessibility in their jurisdictions. Under section 203 of the Voting Rights Act, this language accessibility...",
            img: "s2.png"
        },
        {
            title: "Coordinate",
            des: "Through coalition-building, we coo rdinate responses to key policy decisions, ensuring that senators and representatives know that Muslim communities have a stake in key legislative decisions.",
            img: "s3.png"
        },
        {
            title: "Coalition-Building",
            des: "Through coalition-building, we coordinate responses to key policy decisions, ensuring that senators and representatives know that Muslim communities have a stake in key legislative decisions.",
            img: "s4.png"
        },
        {
            title: "Provide Platform",
            des: "We provide a platform for elected officials, experts and community organizers to convene and share their knowledge and insights on the legislative and civic...",
            img: "s5.png"
        },
        {
            title: "Analyze",
            des: "We gather, analyze and disseminate data and research to policy-makers and stakeholders, providing them with the data-based evidence they need to make informed decisions regarding ...",
            img: "s6.png"
        },
    ];
    return (
        <div className="service-section">
            <h4>Muslims Vote Project works to ensure that our communities’ voices and priorities are included in legislation <br /> related to voter protection, voting rights and other important civic issues.
                <br />  Our Election Protection initiative entails the following:</h4>
            <Row>

                {data?.map((item, k) => (

                    <Col xs={12} sm={6} lg={4} key={k} data-aos="zoom-out" data-aos-duration="1000" data-aos-delay={500 * k}>
                        <ServiceItem data={item} />
                    </Col>
                ))}
            </Row>
        </div >
    )
}

export default Service
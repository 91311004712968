import React from 'react'
import { Col, Row, Image, InputGroup, Form } from 'react-bootstrap'
import TogatherImg from './../../assets/images/wedo.png'
import { FaLongArrowAltRight } from "react-icons/fa";
const Togather = () => {
    return (
        <div className='togather-section'>
            <h1 data-aos="zoom-in" data-aos-duration="1000">Together We Can Do</h1>
            <Row>
                <Col md={10} className="mx-auto">
                    <Row>
                        <Col md={12} lg={5} className="text-center">
                            <Image src={TogatherImg} alt='toghather' data-aos="slide-right" data-aos-duration="1000" className="img-fluid" />
                        </Col>
                        <Col md={12} lg={7}>
                            <div className="togather-form-section" data-aos="slide-left" data-aos-duration="1000">
                                <p>We can not do this huge task without your help. We need to get together and part of the movement to organise and mobilize the muslim community.</p>
                                <InputGroup className="mb-3">
                                    <Form.Control
                                        placeholder="Update Your Vote Info"
                                        aria-describedby="basic-addon1"
                                    />
                                    <InputGroup.Text id="basic-addon1"><FaLongArrowAltRight /></InputGroup.Text>
                                </InputGroup>


                                <Form.Label htmlFor="basic-url">If you have not registered to vote please register.</Form.Label>
                                <InputGroup className="mb-3">
                                    <Form.Control
                                        placeholder="Register To Vote"
                                        aria-describedby="basic-addon1"
                                    />
                                    <InputGroup.Text id="basic-addon1"><FaLongArrowAltRight /></InputGroup.Text>
                                </InputGroup>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>

        </div>
    )
}

export default Togather
import React from 'react'
import TopHeader from '../components/topheader'
import MomentTop from '../section/momenttop'
import Banker from '../section/banker'
import Getinvoled from '../section/getinvole'
const Moment = () => {
    document.title = "MUSLIM VOTE | JOIN THE MOVEMENT";
    const topText = {
        title: "Join the movement",
    }
    return (
        <div>
            <TopHeader text={topText} />
            <MomentTop />
            <Banker />
            <Getinvoled />
        </div>
    )
}

export default Moment
import React from 'react'
import TopHeader from '../components/topheader'
import Service from '../section/services'
import Impact from '../section/impact'
import Getinvoled from '../section/getinvole'
const Protection = () => {
    document.title = "MUSLIM VOTE | VOTER PROTECTION";
    const topText = {
        title: "VOTER PROTECTION",
    }
    return (
        <>
            <TopHeader text={topText} />
            <Service />
            <Impact />
            <Getinvoled />
        </>
    )
}

export default Protection
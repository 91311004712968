import React from 'react'
import Fram from './../../assets/images/fram.png'
import { Image } from 'react-bootstrap'
const Team = () => {
    return (
        <div className='team-section'>
            <Image src={Fram} alt='team' data-aos="zoom-in" data-aos-duration="1000" />
        </div>
    )
}

export default Team
import React from 'react'
import { Navbar, Nav, NavDropdown, Image } from 'react-bootstrap'
import Logo from './../../assets/images/logo.svg';
import { FaFacebookF, FaInstagram, FaTwitter, FaYoutube } from "react-icons/fa";
import { Link } from 'react-router-dom';

const Header = () => {
    return (
        <Navbar collapseOnSelect expand="lg" variant="dark">
            <Navbar.Brand href="/">
                <Image src={Logo} alt="logo" width={120} />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="me-auto">
                    <Link className="nav-link active" to="/" >Home</Link>
                    <NavDropdown title="Inititative" id="collasible-nav-dropdown">
                        <Link className=' dropdown-item' to="/education">Voter Education</Link>
                        <Link className=' dropdown-item' to="/protection">Voter Protection</Link>
                        <Link className=' dropdown-item' to="/moment">Join The Movement</Link>
                    </NavDropdown>

                    <Link className="nav-link" to="/manage">Mission & Vision</Link>
                    <Link className="nav-link" to="/partner">Partner with us</Link>
                    <Link className="nav-link" to="/volunteer">Volunteer with us</Link>
                    <Link className="nav-link" to="/about">About us</Link>

                </Nav>
                <Nav className="icon-nav">
                    <Link className="nav-link" to="#deets"><FaFacebookF /></Link>
                    <Link className="nav-link" to="#deets"><FaInstagram /></Link>
                    <Link className="nav-link" to="#deets"><FaTwitter /></Link>
                    <Link className="nav-link" to="#deets"><FaYoutube /></Link>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    )
}

export default Header
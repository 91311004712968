import React from 'react'
import { Col, Row, Image } from 'react-bootstrap'
import circle from './../../assets/images/circle.svg'
import doc from './../../assets/images/doc.svg'
import hand from './../../assets/images/hand.svg'
const AboutTop = () => {
    return (
        <div className='about-top overflow-hidden'>
            <Row>
                <Col md={11} className='mx-auto' >

                    <Row>
                        <Col xs={12} md={6} lg={4} data-aos="zoom-out" data-aos-duration='1000'>
                            <div className='about-top-image'>
                                <Image src={require('./../../assets/images/about1.png')} alt="about1" />
                                <div className='about-text-overlay'>
                                    <p>MVP is the nation’s leading non-profit dedicated towards engaging, educating, and empowering MVP communities to strengthen their voices and create impact. </p>
                                </div>
                            </div>
                        </Col>
                        <Col xs={12} md={6} lg={4} data-aos="zoom-out" data-aos-duration='1000' data-aos-delay="500">
                            <div className='about-top-image'>
                                <Image src={require('./../../assets/images/about2.png')} alt="about2" />
                                <div className='about-text-overlay'>
                                    <p>For years, MVP has been at the forefront of a rising movement to ensure MVPs are represented and heard, leading to historic voter turnout and advancing equity for MVP communities.</p>
                                </div>
                            </div>
                        </Col>
                        <Col xs={12} md={6} lg={4} data-aos="zoom-out" data-aos-duration='1000' data-aos-delay="1000">
                            <div className='about-top-image'>
                                <Image src={require('./../../assets/images/about3.png')} alt="about3" />
                                <div className='about-text-overlay'>
                                    <p>As MVP continues to grow, we would like to remind our members, friends, partners and supporters that our objectives go beyond just voting in principle.</p>
                                </div>
                            </div>
                        </Col>
                    </Row>


                    <Row className="my-5 about-main" data-aos="slide-right" data-aos-duration='1000' >
                        <Col xs={12} md={6} lg={7}>
                            <Image src={require('./../../assets/images/about_main.png')} alt="about1" />
                        </Col>
                        <Col xs={12} md={6} lg={5}>
                            <div className='about-main-text'>
                                <h2>Investing in our youth:</h2>
                                <ul>
                                    <li>Encouraging a more cooperative environment between diverse communities and organizations.</li>
                                    <li>Establishing a stronger institutional presence for Muslims in America.</li>
                                    <li>Making America a better place for all.</li>
                                    <li>Establishing a stronger institutional presence for young Muslims in America.</li>
                                </ul>
                            </div>
                        </Col>
                    </Row>

                    <Row className='my-5' data-aos="slide-left" data-aos-duration='1000' >
                        <Col xs={12} md={12} lg={12}>
                            <div className="text-div">
                                <h1>Empowering and building our human resources to effect positive change in our community. All the roadmaps that we draw, leads to one destination: the <span>development , refinement</span> and <span>growth</span> of our human resources.</h1>
                                <p>We invite you to check out our core departments</p>
                                <ul>
                                    <li><Image src={circle} alt='cicle' /> Youth</li>
                                    <li><Image src={doc} alt="doc" /> Tarbiya</li>
                                    <li><Image src={hand} alt="hand" /> Outreach</li>
                                </ul>
                                <h1>These three departments represent the structure and methodology by which MVP seeks to develop our human resources.  Join us by contributing towards our mission. Your support of any MVP project, program, or initiative will ultimately benefit these three departments and their mission. Your time and commitment keeps us moving.</h1>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    )
}

export default AboutTop
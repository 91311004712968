import React from 'react'

const ValunteerText = () => {
    return (
        <div className='valunteer-text'>
            <h1 data-aos="zoom-out" data-aos-duration="1000">VOTER HOTLINE OPERATORS</h1>

            <p data-aos="zoom-out" data-aos-duration="1000">Volunteers are essential to sustaining many of our outreach programs and initiatives. If you are looking for an opportunity to make an impact in your community during an election, or throughout the year, we welcome you to join our national corps of volunteers. Here are a few opportunities for you to consider:
                We support state-based “anchors,” such as state nonprofit associations and civic engagement initiatives, who in turn recruit and support nonprofits to do on-the-ground voter engagement work with the Muslim communities.</p>
            <span>For more information please email us at info@muslimvotes.org</span>

            <br />
            <br />
            <br />

            <h1 data-aos="zoom-out" data-aos-duration="1000">VOTER HOTLINE OPERATORS</h1>
            <p data-aos="zoom-out" data-aos-duration="1000">888-MVP-VOTE is a hotline offering assistance to voters in 4 Asian languages! We need volunteers to assist voters with their questions.
                Volunteer shifts are 4 hours each and fairly easy. Simply keep your computer open in the background as you go about your day. Once you hear a chime, you know there’s a voter who needs your help.
                Volunteers are encouraged to sign up for multiple shifts. Once you sign up, we will reach out to you to get you trained.
                Sign up for a shift in any of the following languages: English, Bengali, Hindi/Urdu.</p>

            <br />
            <br />
            <h1 data-aos="zoom-out" data-aos-duration="1000">PHONE AND TEXT BANKERS</h1>

            <p data-aos="zoom-out" data-aos-duration="1000">Volunteer phone and text bankers help us communicate directly with million of Muslims across NYC to get them educated, prepared, motivated to vote and participate in the political process.</p>
        </div>
    )
}

export default ValunteerText
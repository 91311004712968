import React from 'react'
import Header from '../../section/header'
import Footer from '../../section/footer'



const Layout = ({ children }) => {
    return (
        <div className="wrap">
            <Header />
            {children}
            <Footer />
        </div>
    )
}

export default Layout
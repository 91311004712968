import React from 'react'
import { Button } from 'react-bootstrap'

const Movement = () => {
    return (
        <div className='movement-section'>
            <h1>Join The Movement</h1>
            <p>MAKE A DIFFERENCE AND JOIN OUR TEAM.</p>
            <Button variant='danger'>GET involed</Button>
        </div>
    )
}

export default Movement
import React from 'react'
import { Col, Row, Image } from 'react-bootstrap'
import youth from './../../assets/images/ym.svg'
const Youth = () => {
    return (
        <div className="youth-section">
            <Row>
                <Col md={12} lg={6} data-aos="slide-right" data-aos-duration="1000" >
                    <div className="text-youth-div">
                        <h1>Young Muslims Decmocratic club</h1>
                        <p>Our future is in our Youth, we are building leadership pipeline for them by getting opportunities and internships in the mayor office, borough president office and local city councils. We want to get the Muslim youth chair on the table where decisions are made.</p>
                    </div>
                </Col>
                <Col md={12} lg={6} data-aos="zoom-in" data-aos-duration="1000" >
                    <div className='img-youth-div'>
                        <Image src={youth} alt="youth" />
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default Youth
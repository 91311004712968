import React from 'react'
import { Image, Button } from 'react-bootstrap'
import VoteImg from './../../assets/images/vote.png'
const Vote = () => {
    return (
        <div className='vote-section overflow-hidden'>
            <div className='img-div vote' data-aos="slide-down" data-aos-duration="1000">
                <Image src={VoteImg} className='img-fluid' width={"100%"} alt='vote' />
            </div>
            <div className='text-div vote' data-aos="slide-up" data-aos-duration="1000">
                <h1>YOUR VOTE IS YOUR POWER TO EMPOWER YOUR COMMUNITY.</h1>
                <p>Your vote means more funding and resources for the Muslim Community.</p>
                <Button variant='danger'>read more</Button>
            </div>
        </div>
    )
}

export default Vote
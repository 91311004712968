import './assets/css/index.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'aos/dist/aos.css';

import { BrowserRouter } from "react-router-dom";
import RouteFile from './routes';
import AOS from 'aos';

function App() {
  AOS.init();
  return (
    <BrowserRouter>
      <RouteFile />
    </BrowserRouter>
  );
}

export default App;

import React from 'react'
import { Image } from 'react-bootstrap'

const Slide = ({ img }) => {
    return (
        <div className='slide-item'>
            <Image src={require(`./../../assets/images/${img}`)} alt="gallary" />
        </div>
    )
}

export default Slide
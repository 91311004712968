import React from 'react'
import { Col, Image, Row, Button } from 'react-bootstrap'

const About = () => {
    return (
        <div className="about-section " >
            <Row>
                <Col md={11} className="mx-auto overflow-hidden">
                    <Row>
                        <Col md={12} lg={6} data-aos="slide-down" data-aos-duration="1000">
                            <Image src={require('./../../assets/images/education_top.png')} className='img-fluid' alt='education' />
                        </Col>
                        <Col md={12} lg={6}>
                            <div className="about-text-section" data-aos="slide-down" data-aos-duration="1000" data-aos-delay="500">
                                <h1>Voter Education</h1>
                                <p>A critical component of Muslims Vote Project mission is to educate and empower newer immigrants to participate in the electoral process. By conveying culturally appropriate and translated messages and engaging trusted messengers and outlets, we can effectively engage this base of voters.</p>
                                <Button variant='danger'>Read more</Button>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    )
}

export default About
import React from 'react'
import { Col, Row, Image, Dropdown, DropdownButton, InputGroup, Form, Button } from 'react-bootstrap'

const ValunteerTop = () => {
    return (
        <div className="valunteer-section">
            <h1>Become A Volunteer</h1>

            <Row>
                <Col md={11} className='mx-auto'>
                    <Row>
                        <Col md={12} lg={4} className='mb-3 text-center' data-aos="slide-right" data-aos-duration="1000" >
                            <Image src={require('./../../assets/images/valunteer.png')} className='img-fluid' />
                        </Col>
                        <Col md={12} lg={8}>
                            <div className="form-section">
                                <Form.Group className="mb-4" controlId="exampleForm.ControlInput1" data-aos="zoom-in" data-aos-duration="1000" >
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control type="text" placeholder="Your name" />
                                </Form.Group>


                                <Form.Group className="mb-4" controlId="exampleForm.ControlInput1" data-aos="zoom-in" data-aos-duration="1000" data-aos-delay="500" >
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control type="email" placeholder="you@company.com" />
                                </Form.Group>


                                <Form.Group className="mb-4" controlId="exampleForm.ControlInput1" data-aos="zoom-in" data-aos-duration="1000" data-aos-delay="1000">
                                    <Form.Label>Phone number</Form.Label>
                                    <InputGroup className="mb-4">
                                        <DropdownButton
                                            variant="outline-secondary"
                                            title="US"
                                            id="input-group-dropdown-1"
                                        >
                                            <Dropdown.Item href="#">PK</Dropdown.Item>
                                            <Dropdown.Item href="#">US</Dropdown.Item>
                                            <Dropdown.Item href="#">UK</Dropdown.Item>
                                        </DropdownButton>
                                        <Form.Control aria-label="Text input with dropdown button" placeholder='+1 (555) 000-0000' />
                                    </InputGroup>
                                </Form.Group>

                                <Button variant='danger' className="w-100" data-aos="zoom-in" data-aos-duration="1000" data-aos-delay="1500">SUBMIT</Button>

                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row >
        </div >
    )
}

export default ValunteerTop
import React from 'react'
import { Col, Row, Image } from 'react-bootstrap'
const Power = () => {
    const data = [
        {
            title: "Fastest Growing Population",
            text: "In the past two decades, Muslim Americans have become one of the fastest growing racial or ethnic groups in the United States.",
            img: "icon1.svg",
        },
        {
            title: "We Shape Elections",
            text: "The Muslim Americans electorate is a formidable community with the power to shape and influence elections down the ticket throughout the state New York.",
            img: "icon2.svg"
        },
        {
            title: "Representation Matters",
            text: "The decisions made by policymakers and our representatives at all levels of govern impact sour day-to-day lives – from how much funding local schools get to policies around crime prevention and gun safety.",
            img: "icon3.svg"
        },
        {
            title: "Impactful Economic Strength",
            text: "The growth of MVP influence and power extends beyond the political sphere, into all aspects of American society.",
            img: "icon4.svg"
        },

    ];

    return (
        <div className='power-section'>
            <h1>Claim  Your Power</h1>
            <Row>
                {data.map((item, k) => (
                    <Col md={6} lg={3} key={k} data-aos="slide-right" data-aos-duration="1000" data-aos-delay={500 * k}>
                        <div className="power-item" >
                            <span>{item.title}</span>
                            <p>{item.text}</p>
                            <div className="bottom-div">
                                <Image src={require(`./../../assets/images/${item?.img}`)} alt="icon" />
                            </div>
                        </div>


                    </Col>
                ))}

            </Row>
        </div>
    )
}

export default Power
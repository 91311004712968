import React from 'react'
import { Col, Image, Row } from 'react-bootstrap'

const OurPartner = () => {
    return (
        <div className='ourpartner-section'>
            <h1>PARTNERS IN THE MOVEMENT</h1>
            <p>Does this sound like your organization? <a href="">Partner with us</a></p>

            <Row>
                <Col md={11} className='mx-auto'>
                    <Row className='p-3'>
                        <Col md={2} className='mb-2' data-aos="zoom-in" data-aos-duration="1000">
                            <Image src={require('./../../assets/images/p1.png')} className='img-fluid' alt="partner 1" />
                        </Col>
                        <Col md={2} className='mb-2' data-aos="zoom-in" data-aos-duration="1000" data-aos-delay="500">
                            <Image src={require('./../../assets/images/p2.png')} className='img-fluid' alt="partner 2" />
                        </Col>
                        <Col md={2} className='mb-2' data-aos="zoom-in" data-aos-duration="1000" data-aos-delay="1000">
                            <Image src={require('./../../assets/images/p3.png')} className='img-fluid' alt="partner 3" />
                        </Col>
                        <Col md={2} className='mb-2' data-aos="zoom-in" data-aos-duration="1000" data-aos-delay="1500">
                            <Image src={require('./../../assets/images/p4.png')} className='img-fluid' alt="partner 4" />
                        </Col>
                        <Col md={4} className='mb-2' data-aos="zoom-in" data-aos-duration="1000" data-aos-delay="2000">
                            <Image src={require('./../../assets/images/p5.png')} className='img-fluid' alt="partner 5" />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    )
}

export default OurPartner
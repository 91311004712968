import React from 'react'
import TopHeader from '../components/topheader'
import About from '../section/about'
import GallarySlider from '../section/gallaryslider'
import Getinvoled from '../section/getinvole'
const Education = () => {
    document.title = "MUSLIM VOTE | VOTER EDUCATION";
    const topText = {
        title: "VOTER EDUCATION",
    }
    return (
        <>
            <TopHeader text={topText} />
            <About />
            <GallarySlider />
            <Getinvoled />
        </>
    )
}

export default Education
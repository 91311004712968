import React from 'react'
import { Col, Row } from 'react-bootstrap';
import Slider from "react-slick";
import Slide from '../../components/slide';


const GallarySlider = () => {

    var settings = {
        dots: true,
        infinite: false,
        speed: 500,
        autoplay: true,
        slidesToShow: 4,
        slidesToScroll: 2,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    const data = ["g1.png", "g2.png", "g3.png", "g4.png", "g4.png", "g1.png", "g2.png", "g3.png", "g4.png", "g4.png"];

    return (
        <div className='gallary-section'>

            <Row>
                <Slider {...settings}>
                    {data?.map((item, k) => (

                        <Col md={3} key={k} data-aos="zoom-in" data-aos-duration="1500">
                            <Slide img={item} />
                        </Col>
                    ))}
                </Slider>
            </Row>
        </div>
    )
}

export default GallarySlider
import React from 'react'
import { Routes, Route } from "react-router-dom";
import Layout from "./../components/layouts";


//  Pages
import Home from '../pages/Home';
import Manage from '../pages/Manage';
import Education from '../pages/Education';
import Protection from '../pages/Protection';
import Moment from '../pages/Moment';
import Volunteer from '../pages/Volunteer';
import About from '../pages/About';
import Partner from '../pages/Partner';






const index = () => {
    return (
        <Layout>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/manage" element={<Manage />} />
                <Route path="/education" element={<Education />} />
                <Route path="/protection" element={<Protection />} />
                <Route path="/moment" element={<Moment />} />
                <Route path="/volunteer" element={<Volunteer />} />
                <Route path="/about" element={<About />} />
                <Route path="/partner" element={<Partner />} />
            </Routes>
        </Layout>
    )
}

export default index
import React from 'react'
import { Col, Row, Image } from 'react-bootstrap'

const Mission = ({ data }) => {
    return (
        <div className={`Mission-section ${data?.bg ? data?.bg : 'blue'}`}>
            {data?.side == 'left' ?
                <Row className="overflow-hidden">
                    <Col md={7}>
                        <div className='img-div-left overflow-hidden'>
                            <Image src={require(`./../../assets/images/${data?.img}`)} alt='' data-aos="slide-right" data-aos-duration="1000" />
                        </div>
                    </Col>
                    <Col md={5}>
                        <div className='text-section-mission overflow-hidden' data-aos="slide-left" data-aos-duration="1000">
                            <h1>{data?.title}</h1>
                            {data?.des ? <h4>{data?.des}</h4> : ""}
                            {data?.ul ?
                                <>
                                    <ul>
                                        {data?.ul.map((item, k) => (
                                            <li key={k}>{item}</li>
                                        ))}
                                    </ul>
                                </>
                                : ''}
                            {data?.text ? <p>{data?.text}</p> : ''}
                        </div>
                    </Col>
                </Row>
                : data?.side == 'right' ?
                    <Row className="overflow-hidden">
                        <Col md={5} className="right-md-5 ">
                            <div className='text-section-mission overflow-hidden' data-aos="slide-left" data-aos-duration="1000">
                                <h1>{data?.title}</h1>
                                {data?.des ? <h4>{data?.des}</h4> : ""}
                                {data?.ul ?
                                    <>
                                        <ul>
                                            {data?.ul.map((item, k) => (
                                                <li key={k}>{item}</li>
                                            ))}
                                        </ul>
                                    </>
                                    : ''}
                                {data?.text ? <p>{data?.text}</p> : ''}
                            </div>
                        </Col>
                        <Col md={7} className='right'>
                            <div className='img-div-right' data-aos="slide-right" data-aos-duration="1000">
                                <Image src={require(`./../../assets/images/${data?.img}`)} alt='' />
                            </div>
                        </Col>
                    </Row>

                    :
                    <Row className="overflow-hidden">

                        <Col md={6} className='reverse'>
                            <div className='img-div-reverse overflow-hidden'>
                                <Image src={require(`./../../assets/images/${data?.img}`)} alt='' data-aos="slide-right" data-aos-duration="1000" />
                            </div>
                        </Col>

                        <Col md={6}>
                            <div className='text-section-mission' data-aos="slide-left" data-aos-duration="1000">
                                <h1>{data?.title}</h1>
                                {data?.des ? <h4>{data?.des}</h4> : ""}
                                {data?.ul ?
                                    <>
                                        <ul>
                                            {data?.ul.map((item, k) => (
                                                <li key={k}>{item}</li>
                                            ))}
                                        </ul>
                                    </>
                                    : ''}
                                {data?.text ? <p>{data?.text}</p> : ''}
                            </div>
                        </Col>
                    </Row>
            }


        </div>
    )
}

export default Mission
import React from 'react'
import TopHeader from '../components/topheader'
import Team from '../section/team'
import TextSection from '../section/textsection'
import Mission from '../section/mission'
import Vote from '../section/vote'
import Youth from '../section/youth'
import Power from '../section/power'
import Movement from '../section/movement'
import Togather from '../section/togather'
import Getinvoled from '../section/getinvole'

const Home = () => {
    document.title = "MUSLIM VOTE | HOME";

    const topText = {
        title: "We want to prepare our",
        des: "next generation of Muslim leadership",
        title1: `to sit on The table where decisions are  made.`
    }
    const mission = {
        title: "Mission",
        des: "We want an American where Muslims can become City Council, Mayors, Member of Congress and US presidents.",
        side: "left",
        img: "mission.png"
    }
    const vission = {
        title: "Vision",
        des: "We want to prepare our next generation of Muslim leadership at local and national levels to represent the under represented and underserved Muslim community.",
        side: "right",
        img: "vission.png"
    }
    return (
        <>
            <TopHeader text={topText} />
            <Team />
            <TextSection />
            <Mission data={mission} />
            <Mission data={vission} />
            <Vote />
            <Power />
            <Youth />
            <Movement />
            <Togather />
            <Getinvoled />

        </>
    )
}

export default Home
import React from 'react'

const TopHeader = ({ text }) => {
    return (
        <div className='top-header'>
            <div className='overlay'>
                {text?.title ? <h1 data-aos="slide-down" data-aos-duration="1000">{text?.title}</h1> : ""}
                {text?.des ? <h3 data-aos="slide-left" data-aos-duration="1000">{text?.des}</h3> : ''}
                {text?.title1 ? <h1 data-aos="slide-up" data-aos-duration="1000">{text?.title1}</h1> : ''}

            </div>
        </div>
    )
}

export default TopHeader
import React from 'react'
import { Col, Row, Image } from 'react-bootstrap'

const Banker = () => {
    return (
        <div className="banker-section" data-aos="slide-left" data-aos-duration="1000">
            <Row>
                <Col md={11} className="mx-auto">
                    <Row>
                        <Col md={6}>
                            <Image src={require('./../../assets/images/banker.png')} alt='banker' className='img-fluid' />
                        </Col>
                        <Col md={6}>
                            <h1>PHONE AND TEXT BANKERS</h1>
                            <p>Volunteer phone and text bankers help us communicate directly with million of Muslims across to get them educated, prepared, motivated to vote and participate in the political process cultivation.</p>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    )
}

export default Banker
import React from 'react'
import TopHeader from '../components/topheader'
import ValunteerTop from '../section/valunteertop'
import ValunteerText from '../section/valunteertext'
import Getinvoled from '../section/getinvole'
const Volunteer = () => {
    document.title = "MUSLIM VOTE | VALUNTEER WITH US";
    const topText = {
        title: "Volunteer WITH US",
    }
    return (
        <div>
            <TopHeader text={topText} />
            <ValunteerTop />
            <ValunteerText />
            <Getinvoled />
        </div>
    )
}

export default Volunteer